import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../constants';
import { providerParamsToFormData } from '../../helpers';
import { getAccessToken } from '../../service/auth';
import { TProviderColors } from '../../components/ProviderColor';
import { ProviderScope } from './settings';
import { EditProviderParams } from '../../components/EditProvider';
import { licensesApi } from './licenses';

export enum OauthProviderType {
  GOOGLE = 'GOOGLE',
  YANDEX = 'YANDEX',
  VK = 'VK',
  MAILRU = 'MAILRU',
  CUSTOM = 'CUSTOM',
}

export enum MiscProviderType {
  LDAP = 'LDAP',
  ALDPRO = 'ALDPRO',
  CREDENTIALS = 'CREDENTIALS',
  EMAIL = 'EMAIL',
  ETHEREUM = 'ETHEREUM',
  SMS = 'SMS',
  _1C = 'Provider1C',
  IDM = 'IDM',
  QRCODE = 'QRCODE',
  KLOUD = 'KLOUD',
  OAUTH = 'OAUTH',
  PHONE = 'PHONE',
  ESIA = 'ESIA',
}

export type TOauthProvider = {
  id: string;
  name: string;
  type: OauthProviderType;
  description?: string;
  avatar?: string | null;
  path_to_avatar?: string;
  is_active: boolean;
  is_public: boolean;
  scopes?: string;
  client_id: string;
  issuer: string;
  auto_registration: boolean;
  auth_without_email: boolean;
  password_required: boolean;
  token_endpoint: string;
  external_client_id: string;
  external_client_secret: string;
  authorization_endpoint: string;
  redirect_uri: string;
  userinfo_endpoint: string;
  mapping?: string;
  params: EditProviderParams;
};

export type TLdapParams = {
  url: string;
  base: string;
  domain: string;
  mapping: string;
  search_filter: string;
  admin_login: string;
  admin_password: string;
};

export type T1CParams = {
  url: string;
  admin_login: string;
  admin_password: string;
  secret: string;
  mapping: string;
};

export type TIdmParams = {
  url: string;
  groups: string;
  mapping: string;
};

export type TEsiaParams = {
  scopes: string;
  external_client_id: string;
  issuer: string;
  certificate: string;
  sign_endpoint: string;
  verify_endpoint: string;
  // authorization_endpoint: string;
  // token_endpoint: string;
  // userinfo_endpoint: string;
  // mapping: string;
};

export type TKloudParams = {
  external_client_id: string;
  external_client_secret: string;
  issuer: string;
};

export type TEmailParams = {
  root_mail: string;
  mail_hostname: string;
  mail_port: string;
  mail_password: string;
  mail_code_ttl_sec: string;
};

export type TMiscProvider = {
  id: string;
  name: string;
  client_id: string;
  type?: MiscProviderType;
  description?: string;
  avatar?: string | null;
  path_to_avatar?: string;
  is_active: boolean;
  is_public: boolean;
  auto_registration: boolean;
  auth_without_email: boolean;
  password_required: boolean;
  params?: TLdapParams | T1CParams | TIdmParams | TKloudParams | TEmailParams | TEsiaParams;
  provider_colors?: TProviderColors;
  provider_title?: string;
  show_provider_avatar?: boolean;
  disable_password_reset?: boolean;
};

export enum EGetProviderAction {
  'add' = 'add',
  'auth' = 'auth',
  'change' = 'change',
}

export type TEditProvider<T = TMiscProvider> = {
  isOpen: boolean;
  close: () => void;
  providerToEdit: Partial<T & { provider_id: string }> | null;
  scope: ProviderScope;
};

export type TCreateProvider = {
  isOpen: boolean;
  close: (createChooseProvider?: boolean) => void;
  pathToAvatar: string;
  scope: ProviderScope;
};

export const providerApi = createApi({
  reducerPath: 'providerApi',
  tagTypes: ['Providers'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/v1/clients`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getProviders: builder.query<
      (TOauthProvider | TMiscProvider)[],
      { client_id: string; onlyActive: boolean; action: EGetProviderAction }
    >({
      query: ({ client_id, onlyActive, action }) =>
        `${client_id}/providers?only_active=${onlyActive}&action=${action}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Providers' as const,
                id,
              })),
              { type: 'Providers', id: 'LIST' },
            ]
          : [{ type: 'Providers', id: 'LIST' }],
    }),

    getSecret: builder.query<string, { client_id: string; provider_id: string }>({
      query: ({ client_id, provider_id }) => ({
        url: `${client_id}/providers/${provider_id}/secret`,
        responseHandler: (response) => response.text(),
      }),
    }),

    createProvider: builder.mutation({
      query: ({
        body,
        client_id,
      }: {
        body: Partial<
          Omit<TOauthProvider | TMiscProvider, 'avatar'> & {
            avatar?: File | null;
            provider_id: string;
          }
        >;
        client_id: string;
      }) => ({
        url: `${client_id}/providers`,
        method: 'POST',
        body: providerParamsToFormData(body),
      }),
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
    }),

    updateProvider: builder.mutation({
      query: ({
        body,
        client_id,
      }: {
        body: Partial<
          Omit<TOauthProvider | TMiscProvider, 'avatar'> & {
            avatar?: File | null;
            provider_id: string;
            redirect_uri: string;
          }
        >;
        client_id: string;
      }) => ({
        url: `${client_id}/providers/${body.provider_id}`,
        method: 'PUT',
        body: providerParamsToFormData(body),
      }),
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(licensesApi.util.invalidateTags(['Licenses']));
      },
    }),

    activateProviders: builder.mutation({
      query: ({
        body,
        client_id,
      }: {
        body: {
          providers: {
            id: string;
            client_id: string;
          }[];
        };
        client_id: string;
      }) => ({
        url: `${client_id}/providers/activate`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
    }),

    deactivateProviders: builder.mutation({
      query: ({
        body,
        client_id,
      }: {
        body: {
          providers: {
            id: string;
            client_id: string;
          }[];
        };
        client_id: string;
      }) => ({
        url: `${client_id}/providers/deactivate`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
    }),

    deleteProvider: builder.mutation<
      undefined,
      {
        client_id: string;
        provider_id: string;
      }
    >({
      query: (body) => {
        return {
          url: `${body.client_id}/providers/${body.provider_id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetProvidersQuery,
  useLazyGetSecretQuery,
  useCreateProviderMutation,
  useActivateProvidersMutation,
  useDeactivateProvidersMutation,
  useDeleteProviderMutation,
  useUpdateProviderMutation,
} = providerApi;
