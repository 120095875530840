import clsx from 'clsx';
import React, { FC } from 'react';
import styles from './Modal.module.css';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { CustomTypography } from '../custom/CustomTypography';

interface IModalDelete {
  isOpen: boolean;
  onAction: () => void;
  onClose: () => void;
  title: string;
  mainMessage: string[];
  additionMessage?: string;
  disabled?: boolean;
  customActionTitle?: string;
}

export const ModalDelete: FC<IModalDelete> = ({
  isOpen,
  onAction,
  onClose,
  title,
  mainMessage,
  additionMessage,
  disabled,
  customActionTitle,
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={styles['modal-container']}>
        <div style={{ display: 'flex' }}>
          <CustomTypography className={clsx('header-2-medium', 'font-golos')}>
            {title}
          </CustomTypography>
          <IconButton onClick={onClose} style={{ marginLeft: 'auto', marginBottom: 16 }}>
            <CloseIcon />
          </IconButton>
        </div>
        <div style={{ marginBottom: 32 }}>
          {mainMessage.map((message, index) => {
            return (
              <CustomTypography className={clsx('text-14')} key={index}>
                {message}
                <br />
              </CustomTypography>
            );
          })}
          <br />
          <CustomTypography className={clsx('text-14')}>{additionMessage}</CustomTypography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="custom" color="secondary" onClick={onClose}>
            Отмена
          </Button>
          <Button
            disabled={disabled}
            onClick={onAction}
            variant="custom"
            color="error"
            style={{ marginLeft: 24 }}
          >
            {customActionTitle || 'Удалить'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
