import React, { useState } from 'react';
import { Button, IconButton, Modal, TextField } from '@mui/material';
import styles from './Modal.module.css';
import { CustomTypography } from '../custom/CustomTypography';
import clsx from 'clsx';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { useActivateLicenseMutation } from 'src/redux/services/licenses';

interface IModalAddLicense {
  isOpen: boolean;
  closeModal: () => void;
}

export const ModalAddLicense = ({ isOpen, closeModal }: IModalAddLicense) => {
  const [inputLicense, setInputLicense] = useState('');
  const [activateLicense] = useActivateLicenseMutation();

  const handleSaveLicense = async () => {
    if (!inputLicense) return;
    activateLicense({ value: inputLicense, description: 'Описание лицензии' });
    setInputLicense('');
    closeModal();
  };

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <div
        className={styles['modal-container']}
        style={{ display: 'flex', flexDirection: 'column', padding: 20 }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <CustomTypography className={clsx('header-2-medium', 'font-golos')}>
            Введите значение
          </CustomTypography>
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </div>
        <TextField
          value={inputLicense}
          onChange={(e) => setInputLicense(e.target.value)}
          fullWidth
          variant="standard"
          style={{ marginBottom: 20 }}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="custom" color="secondary" onClick={closeModal}>
            Отмена
          </Button>
          <Button onClick={handleSaveLicense} variant="custom" style={{ marginLeft: 24 }}>
            Сохранить
          </Button>
        </div>
      </div>
    </Modal>
  );
};
