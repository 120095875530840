import React, { useState } from 'react';
import { CustomTypography } from '../custom/CustomTypography';
import { Button, MenuItem } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomSelect } from '../custom/CustomSelect';
import styles from './LicenseSelect.module.css';
import clsx from 'clsx';
import { ModalAddLicense } from '../modal/ModalAddLicense';
import { useGetLicensesQuery } from 'src/redux/services/licenses';

interface TLicenseSelect {
  flag: string;
}

export const LicenseSelect = ({ flag }: TLicenseSelect) => {
  const [addLicenseModalOpen, setAddLicenseModalOpen] = useState(false);
  const { control } = useFormContext();
  const { data: licenses, isFetching: licensesFetching } = useGetLicensesQuery({ flag });

  const handleCancelLicense = () => {
    setAddLicenseModalOpen(false);
  };

  return (
    <div>
      <div className={clsx(styles['license-title-wrap'], styles['input-title'])}>
        <CustomTypography className={'text-14'}>Лицензия</CustomTypography>
        <Button variant="custom2" onClick={() => setAddLicenseModalOpen(true)}>
          Добавить
        </Button>
      </div>
      <Controller
        control={control}
        name="license_id"
        render={({ field }) => (
          <CustomSelect
            onChange={(e) => {
              field.onChange(e.target.value === '(нет лицензии)' ? '' : e.target.value);
            }}
            className={styles.select}
            value={field.value === '' ? '(нет лицензии)' : field.value}
          >
            <MenuItem className="custom-select" value={'(нет лицензии)'}>
              (нет лицензии)
            </MenuItem>
            {licenses &&
              licenses.map((license) => (
                <MenuItem className="custom-select" key={license.value} value={license.id}>
                  {license.value}
                </MenuItem>
              ))}
          </CustomSelect>
        )}
      />
      <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
        В рамках одной лицензии вы можете использовать один и тот же Адрес сервера
      </CustomTypography>

      {addLicenseModalOpen && (
        <ModalAddLicense isOpen={addLicenseModalOpen} closeModal={handleCancelLicense} />
      )}
    </div>
  );
};
