import clsx from 'clsx';
import React, { FC, FocusEvent, useEffect, useState } from 'react';
import styles from './CreateProvider.module.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  MiscProviderType,
  T1CParams,
  TCreateProvider,
  useCreateProviderMutation,
} from '../redux/services/provider';

import { isObjectEmpty, isOwner } from '../helpers';
import TextField from '@mui/material/TextField';
import { ProviderHeader } from './ProviderHeader';
import { ProviderFooter } from './ProviderFooter';
import { PasswordTextfield } from './custom/PasswordTextfield';
import { useParams } from 'react-router-dom-v5-compat';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';
import { ModalWithAction } from './modal/ModalWithAction';
import { ModalCloseOnly } from './modal/ModalCloseOnly';
import { CustomTypography } from './custom/CustomTypography';
import { ActionButtons } from './sidePanel/ActionButtons';
import { ProviderSettingsSidePanel } from './sidePanel/ProviderSettingsSidePanel';
import { LicenseSelect } from './applications/LicenseSelect';

export type Create1CProviderInputs = {
  name: string;
  description: string;
  avatar: File | null;
  path_to_avatar: string;
  url: string;
  admin_login: string;
  admin_password: string;
  secret: string;
  auto_registration?: boolean;
  mapping: string;
  auth_without_email?: boolean;
  password_required: boolean;
  is_public: boolean;
  disable_password_reset: boolean;
};

const schema = yup.object({
  name: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Название не может превышать 50 символов')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  url: yup
    .string()
    .required('Обязательное поле')
    .max(255, 'Адрес сервера 1C не может превышать 255 символов')
    .url('Введите корректный адрес'),
  admin_login: yup
    .string()
    .required('Обязательное поле')
    .max(255, 'Логин не может превышать 255 символов'),
  secret: yup.string().required('Обязательное поле'),
  admin_password: yup.string().max(255, 'Пароль не может превышать 255 символов'),
  mapping: yup.string().max(1024, 'Поле не может превышать 1024 символов'),
  auto_registration: yup.boolean(),
  auth_without_email: yup.boolean(),
  password_required: yup.boolean(),
  disable_password_reset: yup.boolean(),
  is_public: yup.boolean(),
});

export const Create1CProvider: FC<TCreateProvider> = ({ isOpen, close, pathToAvatar, scope }) => {
  const methods = useForm<Create1CProviderInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      description: '',
      auth_without_email: false,
      auto_registration: false,
      password_required: false,
      disable_password_reset: false,
      is_public: false,
      path_to_avatar: pathToAvatar,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
    reset,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [overrideImage, setOverrideImage] = useState<File | string | null>(null);
  const watchDescription = watch('description');
  const [createProvider, createResult] = useCreateProviderMutation();
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const userRole = useSelector((state: RootState) => state.user.userProfile.role);

  useEffect(() => {
    if (createResult.isSuccess) close(true);
  }, [createResult]);

  useEffect(() => {
    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const setFields = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const provider: Partial<
        Omit<Create1CProviderInputs, 'avatar'> & {
          id: string;
          isPublic: boolean;
          client_id: string;
          params: T1CParams;
          avatar: string;
          type: MiscProviderType._1C;
        }
      > = JSON.parse(text);
      const { type, avatar, params, ...restInputs } = provider || {};
      delete restInputs.id;
      delete restInputs.client_id;
      if (type !== MiscProviderType._1C) {
        setClipboardModalOpen(true);
      } else {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', null);
          setValue('path_to_avatar', avatar, { shouldDirty: !provider });
        }
        if (params) {
          (Object.keys(params) as Array<keyof T1CParams>).forEach((field) => {
            setValue(field, params?.[field] || '', { shouldDirty: !provider });
          });
        }
        if (restInputs) {
          (Object.keys(restInputs) as Array<keyof Omit<Create1CProviderInputs, 'avatar'>>).forEach(
            (field) => {
              if (
                field === 'auto_registration' ||
                field === 'auth_without_email' ||
                field === 'password_required' ||
                field === 'is_public' ||
                field === 'disable_password_reset'
              ) {
                return setValue(field, restInputs?.[field] === true, { shouldDirty: !provider });
              }
              setValue(field, restInputs?.[field] || '', { shouldDirty: true });
            },
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<Create1CProviderInputs> = (data) => {
    const { avatar, ...rest } = data;
    if (!isOwner(userRole)) {
      delete rest.auth_without_email;
      delete rest.auto_registration;
    }
    createProvider({
      body: {
        type: MiscProviderType._1C,
        ...rest,
        avatar: avatar ? avatar : null,
        is_active: true,
      },
      client_id: clientId,
    });
  };

  return (
    <ProviderSettingsSidePanel
      handleClosePanel={handleClose}
      isOpenPanel={isOpen}
      title="Создать провайдер 1C"
      setPasteFields={() => {
        setFields();
      }}
      isNoBackdrop
    >
      <>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
            <div className={styles['create-provider-form']}>
              <ProviderHeader
                type={scope}
                watchDescription={watchDescription}
                overrideImage={overrideImage}
                setAvatarError={setAvatarError}
                clearAvatarError={clearAvatarError}
                setAvatarValue={setAvatarValue}
                setAvatarLink={setAvatarLink}
                imgSrc={avatarSrc}
                setImgSrc={setAvatarSrc}
                pathToAvatar={pathToAvatar}
              />
              <LicenseSelect flag={MiscProviderType._1C} />
              <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
                Адрес сервера 1C
              </CustomTypography>
              <TextField
                {...register('url', {
                  required: true,
                  onBlur: (event: FocusEvent<HTMLInputElement>) => {
                    setValue('url', event.target.value.trim());
                  },
                  onChange: () => {
                    if (errors.url) clearErrors('url');
                  },
                })}
                className="custom"
                error={!!errors.url}
                helperText={errors.url ? errors.url.message : ''}
                fullWidth
                variant="standard"
              />
              <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
                Адрес публикации информационной базы
              </CustomTypography>
              <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
                Логин 1C
              </CustomTypography>
              <TextField
                {...register('admin_login', {
                  required: true,
                  onChange: () => {
                    if (errors.admin_login) clearErrors('admin_login');
                  },
                })}
                className="custom"
                error={!!errors.admin_login}
                helperText={errors.admin_login ? errors.admin_login.message : ''}
                fullWidth
                variant="standard"
              />
              <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
                Логин для доступа к 1C
              </CustomTypography>
              <CustomTypography className={clsx('text-14', styles['input-title'])}>
                Пароль 1C
              </CustomTypography>
              <PasswordTextfield
                {...register('admin_password', {
                  onChange: () => {
                    if (errors.admin_password) clearErrors('admin_password');
                  },
                })}
                className="custom"
                error={!!errors.admin_password}
                helperText={errors.admin_password ? errors.admin_password.message : ''}
                fullWidth
                variant="standard"
              />
              <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
                Пароль для доступа к 1C
              </CustomTypography>
              <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
                Secret
              </CustomTypography>
              <TextField
                {...register('secret', {
                  required: true,
                  onChange: () => {
                    if (errors.secret) clearErrors('secret');
                  },
                })}
                className="custom"
                error={!!errors.secret}
                helperText={errors.secret ? errors.secret.message : ''}
                fullWidth
                variant="standard"
              />
              <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
                Secret
              </CustomTypography>
              <CustomTypography className={clsx('text-14', styles['input-title'])}>
                Сопоставление атрибутов 1C
              </CustomTypography>
              <TextField
                {...register('mapping', {
                  required: true,
                  onChange: () => {
                    if (errors.mapping) clearErrors('mapping');
                  },
                })}
                className="custom"
                error={!!errors.mapping}
                helperText={errors.mapping ? errors.mapping.message : ''}
                fullWidth
                variant="standard"
              />
              <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
                Сопоставление атрибутов профиля пользователя Trusted.ID с атрибутами пользователя
                внешней системы в формате: given_name:firstName, family_name:lastName, email:email,
                picture:photo
              </CustomTypography>
              <ProviderFooter type={MiscProviderType._1C} clientId={clientId} />
            </div>
            <ActionButtons
              handleClose={handleClose}
              acceptTitle="Создать"
              acceptAction={handleSubmit(onSubmit)}
              disabled={createResult.isLoading}
            />
          </form>
        </FormProvider>

        <ModalWithAction
          title="Сохранение изменений"
          message="Изменения не сохранены. Продолжить без сохранения?"
          actionTitle="Продолжить"
          isOpen={saveModalOpen}
          onAction={() => {
            close();
            setSaveModalOpen(false);
          }}
          onClose={closeSaveModal}
        />

        <ModalCloseOnly
          isOpen={clipboardModalOpen}
          onCloseAction={closeClipboardModal}
          title="Вставить настройки"
          message="Скопированные настройки не подходят для 1C-провайдера."
        />
      </>
    </ProviderSettingsSidePanel>
  );
};
