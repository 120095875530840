import React, { FC, useEffect, useState } from 'react';
import styles from './CreateProvider.module.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  MiscProviderType,
  TCreateProvider,
  useCreateProviderMutation,
} from '../redux/services/provider';

import { isObjectEmpty, isOwner } from '../helpers';
import { ProviderHeader } from './ProviderHeader';
import { ProviderFooter } from './ProviderFooter';
import { useParams } from 'react-router-dom-v5-compat';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';
import { ModalWithAction } from './modal/ModalWithAction';
import { ModalCloseOnly } from './modal/ModalCloseOnly';
import { ProviderSettingsSidePanel } from './sidePanel/ProviderSettingsSidePanel';
import { ActionButtons } from './sidePanel/ActionButtons';

export type CreateEthereumProviderInputs = {
  name: string;
  description: string;
  avatar: File | null;
  auto_registration?: boolean;
  auth_without_email?: boolean;
  password_required: boolean;
  is_public: boolean;
  path_to_avatar: string;
};

const schema = yup.object({
  name: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Название не может превышать 50 символов')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  auto_registration: yup.boolean(),
  auth_without_email: yup.boolean(),
  password_required: yup.boolean(),
  is_public: yup.boolean(),
});

export const CreateEthereumProvider: FC<TCreateProvider> = ({
  isOpen,
  close,
  pathToAvatar,
  scope,
}) => {
  const methods = useForm<CreateEthereumProviderInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      description: '',
      auth_without_email: false,
      auto_registration: false,
      password_required: false,
      is_public: false,
      path_to_avatar: pathToAvatar,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields },
    setError,
    clearErrors,
    reset,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [overrideImage, setOverrideImage] = useState<File | null>(null);
  const watchDescription = watch('description');
  const [createProvider, createResult] = useCreateProviderMutation();
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const userRole = useSelector((state: RootState) => state.user.userProfile.role);

  useEffect(() => {
    if (createResult.isSuccess) close(true);
  }, [createResult]);

  useEffect(() => {
    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const setFields = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const provider: Partial<
        CreateEthereumProviderInputs & { id: string; type: MiscProviderType.ETHEREUM }
      > = JSON.parse(text);
      const { type, avatar, ...restInputs } = provider || {};
      delete restInputs.id;

      if (type !== MiscProviderType.ETHEREUM) {
        setClipboardModalOpen(true);
      } else {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', avatar);
        }

        if (restInputs) {
          (
            Object.keys(restInputs) as Array<keyof Omit<CreateEthereumProviderInputs, 'avatar'>>
          ).forEach((field) => {
            if (
              field === 'auto_registration' ||
              field === 'auth_without_email' ||
              field === 'password_required' ||
              field === 'is_public'
            ) {
              return setValue(field, String(restInputs?.[field]) === 'true', {
                shouldDirty: !provider,
              });
            }
            setValue(field, restInputs?.[field] || '', { shouldDirty: true });
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<CreateEthereumProviderInputs> = (data) => {
    const { avatar, ...rest } = data;
    if (!isOwner(userRole)) {
      delete rest.auth_without_email;
      delete rest.auto_registration;
    }
    createProvider({
      body: {
        type: MiscProviderType.ETHEREUM,
        ...rest,
        avatar: avatar ? avatar : null,
      },
      client_id: clientId,
    });
  };

  return (
    <ProviderSettingsSidePanel
      handleClosePanel={handleClose}
      isOpenPanel={isOpen}
      title="Создать способ входа Ethereum"
      setPasteFields={() => {
        setFields();
      }}
      isNoBackdrop
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
          <div className={styles['create-provider-form']}>
            <ProviderHeader
              type={scope}
              watchDescription={watchDescription}
              overrideImage={overrideImage}
              setAvatarError={setAvatarError}
              clearAvatarError={clearAvatarError}
              pathToAvatar={pathToAvatar}
              setAvatarLink={setAvatarLink}
              setAvatarValue={setAvatarValue}
              imgSrc={avatarSrc}
              setImgSrc={setAvatarSrc}
            />
            <ProviderFooter type={MiscProviderType.ETHEREUM} clientId={clientId} />
          </div>
          <ActionButtons
            handleClose={handleClose}
            acceptTitle="Создать"
            acceptAction={handleSubmit(onSubmit)}
            disabled={createResult.isLoading}
          />
        </form>
      </FormProvider>

      <ModalWithAction
        title="Сохранение изменений"
        message="Изменения не сохранены. Продолжить без сохранения?"
        actionTitle="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          close();
          setSaveModalOpen(false);
        }}
        onClose={closeSaveModal}
      />

      <ModalCloseOnly
        isOpen={clipboardModalOpen}
        onCloseAction={closeClipboardModal}
        title="Вставить настройки"
        message="Скопированные настройки не подходят для ETHEREUM-провайдера."
      />
    </ProviderSettingsSidePanel>
  );
};
