import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import React, { ChangeEvent, FC, FocusEvent, useEffect, useState } from 'react';
import styles from './EditProvider.module.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import {
  MiscProviderType,
  TEditProvider,
  TIdmParams,
  TMiscProvider,
  useUpdateProviderMutation,
} from '../redux/services/provider';
import { isObjectEmpty } from '../helpers';
import { ProviderHeader } from './ProviderHeader';
import { ProviderFooter } from './ProviderFooter';
import Switch from '@mui/material/Switch';
import { ProviderColor, TProviderColors } from './ProviderColor';
import { useParams } from 'react-router-dom-v5-compat';
import { ModalWithAction } from './modal/ModalWithAction';
import { ModalCloseOnly } from './modal/ModalCloseOnly';
import { CustomTypography } from './custom/CustomTypography';
import { ProviderSettingsSidePanel } from './sidePanel/ProviderSettingsSidePanel';
import { ActionButtons } from './sidePanel/ActionButtons';
import { LicenseSelect } from './applications/LicenseSelect';

const idmParams = ['url', 'mapping'];

export type EditQRCodeProviderInputs = {
  name: string;
  description: string;
  avatar: File | null;
  path_to_avatar: string;
  license: string;
};

const schema = yup.object({
  name: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Название не может превышать 50 символов')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  license: yup.string(),
});

export const EditQRCodeProvider: FC<TEditProvider> = ({ isOpen, close, providerToEdit, scope }) => {
  const methods = useForm<EditQRCodeProviderInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { dirtyFields },
    setError,
    clearErrors,
    reset,
    control,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [updateProvider, updateIdmResult] = useUpdateProviderMutation();
  const [overrideImage, setOverrideImage] = useState<File | string | null>(null);
  const watchDescription = watch('description');
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);

  useEffect(() => {
    if (updateIdmResult.isSuccess) close();
  }, [updateIdmResult]);

  // useEffect(() => {
  //   if (providerToEdit) setValue('is_public', !!providerToEdit?.is_public);
  // }, [providerToEdit]);

  useEffect(() => {
    if (isOpen && providerToEdit) {
      setFields(providerToEdit);
    }

    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const setFields = async (
    provider?: Partial<TMiscProvider & { provider_id: string; license: string }>,
  ) => {
    try {
      let selectedCopy:
        | Partial<
            TMiscProvider & {
              provider_id: string;
              client_id: string;
              license: string;
            }
          >
        | undefined;
      if (!provider) {
        selectedCopy = JSON.parse(await navigator.clipboard.readText());
      }
      const { avatar, params, type, ...restInputs } = selectedCopy || provider || {};
      delete restInputs.id;
      delete restInputs.client_id;
      if (type !== MiscProviderType.QRCODE) {
        setClipboardModalOpen(true);
      } else {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', null);
          setValue('path_to_avatar', avatar, { shouldDirty: !provider });
        }
        if (restInputs) {
          (
            Object.keys(restInputs) as Array<keyof Omit<EditQRCodeProviderInputs, 'avatar'>>
          ).forEach((field) => {
            setValue(field, restInputs?.[field] || '', { shouldDirty: true });
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<EditQRCodeProviderInputs> = (data) => {
    if (providerToEdit) {
      const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
        (acc: Partial<EditQRCodeProviderInputs>, field) => {
          if (field === 'avatar') {
            acc.avatar = data.avatar;
            return acc;
          }

          acc[field] = data[field];
          return acc;
        },
        {},
      );

      if (!isObjectEmpty(payload)) {
        updateProvider({
          body: {
            ...payload,
            type: MiscProviderType.QRCODE,
            provider_id: providerToEdit.id,
          },
          client_id: clientId,
        });
      }
    }
  };

  return (
    <ProviderSettingsSidePanel
      handleClosePanel={handleClose}
      isOpenPanel={isOpen}
      title="Редактировать провайдер QR-код"
      setPasteFields={() => {
        setFields();
      }}
      isNoBackdrop
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
          <div className={styles['create-provider-form']}>
            <ProviderHeader
              type={scope}
              watchDescription={watchDescription}
              overrideImage={overrideImage}
              setAvatarError={setAvatarError}
              clearAvatarError={clearAvatarError}
              providerToEdit={providerToEdit}
              setAvatarValue={setAvatarValue}
              setAvatarLink={setAvatarLink}
              imgSrc={avatarSrc}
              setImgSrc={setAvatarSrc}
            />
            <LicenseSelect flag={MiscProviderType.QRCODE.toString()} />
            <ProviderFooter type={MiscProviderType.QRCODE} clientId={clientId} />
          </div>
          <ActionButtons
            handleClose={handleClose}
            acceptTitle="Сохранить"
            acceptAction={handleSubmit(onSubmit)}
            disabled={updateIdmResult.isLoading}
          />
        </form>
      </FormProvider>

      <ModalWithAction
        title="Сохранение изменений"
        message="Изменения не сохранены. Продолжить без сохранения?"
        actionTitle="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          close();
          setSaveModalOpen(false);
        }}
        onClose={closeSaveModal}
      />

      <ModalCloseOnly
        isOpen={clipboardModalOpen}
        onCloseAction={closeClipboardModal}
        title="Вставить настройки"
        message="Скопированные настройки не подходят для IDM-провайдера."
      />
    </ProviderSettingsSidePanel>
  );
};
