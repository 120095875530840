import React, { useState } from 'react';
import { CustomTypography } from '../custom/CustomTypography';
import styles from './LicenseItem.module.css';
import clsx from 'clsx';
import { IconWithTooltip } from '../IconWithTooltip';
import { formatDate } from 'src/helpers';
import { TLicenseInfo, useDeleteLicenseMutation } from 'src/redux/services/licenses';
import { useTheme } from '@mui/material';
import { ModalDelete } from '../modal/ModalDelete';

interface ILicenseItem {
  licenseName: string;
  licenseId: string;
  expirationTime: number;
  licenseKeys: string[];
  connectedProvidersList: string[];
}

const LicenseItem = ({
  licenseName,
  licenseId,
  expirationTime,
  licenseKeys,
  connectedProvidersList,
}: ILicenseItem) => {
  const [isDeleteLicenseModalOpen, setIsDeleteLicenseModalOpen] = useState(false);
  const theme = useTheme();
  const [deleteLicense] = useDeleteLicenseMutation();

  const handleDeleteLicense = async () => {
    await deleteLicense({ id: licenseId });
    setIsDeleteLicenseModalOpen(false);
  };

  const isActive = !!connectedProvidersList.length;
  const isExpired = expirationTime && expirationTime * 1000 < Date.now() ? true : false;

  return (
    <div className={styles['item-wrapper']}>
      <div className={styles['item-info-container']}>
        <div className={styles['item-name-container']}>
          <CustomTypography className={clsx('text-14', styles['item-name'])}>
            {licenseName}
          </CustomTypography>
          <div
            className={clsx(
              styles['chip-wrapper'],
              isActive ? styles['active-bg'] : styles['not-active-bg'],
            )}
          >
            <CustomTypography
              className={clsx('text-12')}
              sx={{
                color: isExpired
                  ? theme.palette.error.main
                  : isActive
                  ? theme.palette.custom.onAccentColor
                  : theme.palette.custom.mainGrey,
              }}
            >
              {isExpired ? 'Просрочена' : isActive ? 'Активна' : 'Неактивна'}
            </CustomTypography>
          </div>
          {connectedProvidersList?.map((provider, index) => (
            <div
              key={provider + index}
              className={clsx(styles['chip-wrapper'], styles['not-active-bg'])}
            >
              <CustomTypography
                className={clsx('text-12')}
                sx={{
                  color: theme.palette.custom.mainGrey,
                }}
              >
                {provider}
              </CustomTypography>
            </div>
          ))}
        </div>
        <CustomTypography className={clsx('text-12')} color="grey">
          Срок действия:{' '}
          {expirationTime === 0 ? 'бессрочная' : formatDate(new Date(expirationTime * 1000))}
        </CustomTypography>
        <CustomTypography className={clsx('text-12')} color="grey">
          Ключи: {licenseKeys.join(', ')}
        </CustomTypography>
      </div>
      <IconWithTooltip iconType="delete" action={() => setIsDeleteLicenseModalOpen(true)} />

      {isDeleteLicenseModalOpen && (
        <ModalDelete
          isOpen={isDeleteLicenseModalOpen}
          onAction={handleDeleteLicense}
          onClose={() => setIsDeleteLicenseModalOpen(false)}
          title={'Удалить лицензию'}
          mainMessage={[
            `Провайдеры, к которым привязана лицензия ${licenseName}, перестанут работать`,
          ]}
        />
      )}
    </div>
  );
};

export default LicenseItem;
