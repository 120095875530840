import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import styles from './CreateProvider.module.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  MiscProviderType,
  TCreateProvider,
  TEsiaParams,
  useCreateProviderMutation,
} from '../redux/services/provider';

import { isObjectEmpty, isOwner } from '../helpers';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ProviderHeader } from './ProviderHeader';
import { ProviderFooter } from './ProviderFooter';
import { useParams } from 'react-router-dom-v5-compat';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';
import InputAdornment from '@mui/material/InputAdornment';
import { BACKEND_URL } from '../constants';
import { ModalWithAction } from './modal/ModalWithAction';
import { IconWithTooltip } from './IconWithTooltip';
import { ModalCloseOnly } from './modal/ModalCloseOnly';
import { CustomTypography } from './custom/CustomTypography';
import { ProviderSettingsSidePanel } from './sidePanel/ProviderSettingsSidePanel';
import { ActionButtons } from './sidePanel/ActionButtons';
import { LicenseSelect } from './applications/LicenseSelect';

export type CreateEsiaProviderInputs = {
  name: string;
  description: string;
  avatar: File | null;
  path_to_avatar: string;
  auto_registration?: boolean;
  external_client_id: string;
  certificate: string;
  sign_endpoint: string;
  verify_endpoint: string;
  is_public: boolean;
};

const schema = yup.object({
  name: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Название не может превышать 50 символов')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  certificate: yup.string().required('Обязательное поле'),
  sign_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  verify_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  auto_registration: yup.boolean(),
  is_public: yup.boolean(),
});

export const CreateEsiaProvider: FC<TCreateProvider> = ({ isOpen, close, pathToAvatar, scope }) => {
  const methods = useForm<CreateEsiaProviderInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      description: '',
      auto_registration: false,
      is_public: false,
      path_to_avatar: pathToAvatar,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
    reset,
    control,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [overrideImage, setOverrideImage] = useState<File | string | null>(null);
  const watchDescription = watch('description');
  const [createProvider, createResult] = useCreateProviderMutation();
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const userRole = useSelector((state: RootState) => state.user.userProfile.role);

  useEffect(() => {
    if (createResult.isSuccess) close(true);
  }, [createResult]);

  useEffect(() => {
    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result as ArrayBuffer;
        const base64Content = arrayBufferToBase64(content);
        setValue('certificate', base64Content, { shouldDirty: true });
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const handleButtonClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.crt,.pem,.cer'; // Укажите нужные форматы файлов
    fileInput.addEventListener('change', handleFileChange as unknown as EventListener);
    fileInput.click();
  };

  const setFields = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const provider: Partial<
        Omit<CreateEsiaProviderInputs, 'avatar'> & {
          id: string;
          isPublic: boolean;
          client_id: string;
          params: TEsiaParams;
          avatar: string;
          type: MiscProviderType.ESIA;
        }
      > = JSON.parse(text);
      const { type, avatar, params, ...restInputs } = provider || {};
      delete restInputs.id;
      delete restInputs.client_id;
      if (type !== MiscProviderType.ESIA) {
        setClipboardModalOpen(true);
      } else {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', null);
          setValue('path_to_avatar', avatar, { shouldDirty: !provider });
        }
        if (params) {
          (
            Object.keys(params) as Array<
              keyof Omit<TEsiaParams, 'issuer' | 'certificate' | 'scopes'>
            >
          ).forEach((field) => {
            setValue(field, params?.[field] || '', { shouldDirty: !provider });
          });
        }
        if (restInputs) {
          (
            Object.keys(restInputs) as Array<keyof Omit<CreateEsiaProviderInputs, 'avatar'>>
          ).forEach((field) => {
            if (field === 'auto_registration' || field === 'is_public') {
              return setValue(field, restInputs?.[field] === true, { shouldDirty: !provider });
            }
            setValue(field, restInputs?.[field] || '', { shouldDirty: true });
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<CreateEsiaProviderInputs> = (data) => {
    const { avatar, ...rest } = data;
    if (!isOwner(userRole)) {
      delete rest.auto_registration;
    }
    createProvider({
      body: {
        type: MiscProviderType.ESIA,
        ...rest,
        avatar: avatar ? avatar : null,
        is_active: true,
      },
      client_id: clientId,
    });
  };

  return (
    <ProviderSettingsSidePanel
      handleClosePanel={handleClose}
      isOpenPanel={isOpen}
      title="Создать способ входа ЕСИА"
      setPasteFields={() => {
        setFields();
      }}
      isNoBackdrop
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
          <div className={styles['create-provider-form']}>
            <ProviderHeader
              type={scope}
              watchDescription={watchDescription}
              overrideImage={overrideImage}
              setAvatarError={setAvatarError}
              clearAvatarError={clearAvatarError}
              setAvatarValue={setAvatarValue}
              setAvatarLink={setAvatarLink}
              imgSrc={avatarSrc}
              setImgSrc={setAvatarSrc}
              pathToAvatar={pathToAvatar}
            />
            <LicenseSelect flag={MiscProviderType.ESIA} />
            <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
              Идентификатор ресурса (client_id)
            </CustomTypography>
            <TextField
              {...register('external_client_id', {
                required: true,
                onChange: () => {
                  if (errors.external_client_id) clearErrors('external_client_id');
                },
              })}
              className="custom"
              error={!!errors.external_client_id}
              helperText={errors.external_client_id ? errors.external_client_id.message : ''}
              fullWidth
              variant="standard"
              autoComplete="off"
            />
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Уникальный идентификатор подключаемого ресурса
            </CustomTypography>
            <CustomTypography className={clsx('text-14', styles['input-title'])}>
              Redirect URI
            </CustomTypography>
            <TextField
              value={BACKEND_URL + '/api/interaction/code'}
              disabled
              className="custom"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconWithTooltip
                      iconType="copy"
                      action={() => {
                        navigator.clipboard.writeText(BACKEND_URL + '/api/interaction/code');
                      }}
                    />
                  </InputAdornment>
                ),
                classes: {
                  disabled: styles['input-wrapper-disabled'],
                },
              }}
              fullWidth
              variant="standard"
            />
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Cсылка должна быть указана в настройках внешних способов входа для корректной
              аутентификации пользователя
            </CustomTypography>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
                Сертификат подписи и проверки запросов
              </CustomTypography>
              <Button
                variant="custom2"
                className={styles['input-title']}
                onClick={handleButtonClick}
              >
                Загрузить
              </Button>
            </div>
            <TextField
              {...register('certificate', {
                required: true,
                onChange: () => {
                  if (errors.certificate) clearErrors('certificate');
                },
              })}
              className="custom"
              error={!!errors.certificate}
              helperText={errors.certificate ? errors.certificate.message : ''}
              fullWidth
              variant="standard"
            />
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Сертификат, который с помощью которого подписываются и проверяются запросы
            </CustomTypography>
            <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
              Адрес получения подписи запроса (sign_endpoint)
            </CustomTypography>
            <TextField
              {...register('sign_endpoint', {
                required: true,
                onChange: () => {
                  if (errors.sign_endpoint) clearErrors('sign_endpoint');
                },
              })}
              className="custom"
              error={!!errors.sign_endpoint}
              helperText={errors.sign_endpoint ? errors.sign_endpoint.message : ''}
              fullWidth
              variant="standard"
            />
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Ресурс, который подписывает запрос
            </CustomTypography>
            <CustomTypography className={clsx('text-14', styles.asterisk, styles['input-title'])}>
              Адрес проверки подписи запроса (verify_endpoint)
            </CustomTypography>
            <TextField
              {...register('verify_endpoint', {
                required: true,
                onChange: () => {
                  if (errors.verify_endpoint) clearErrors('verify_endpoint');
                },
              })}
              className="custom"
              error={!!errors.verify_endpoint}
              helperText={errors.verify_endpoint ? errors.verify_endpoint.message : ''}
              fullWidth
              variant="standard"
            />
            <CustomTypography className={clsx('text-14', styles['input-subtitle'])} color="grey">
              Ресурс, который проверяет подпись ответа от ЕСИА
            </CustomTypography>
            <ProviderFooter type={MiscProviderType.ESIA} clientId={clientId} />
          </div>
          <ActionButtons
            handleClose={handleClose}
            acceptTitle="Создать"
            acceptAction={handleSubmit(onSubmit)}
            disabled={createResult.isLoading}
          />
        </form>
      </FormProvider>

      <ModalWithAction
        title="Сохранение изменений"
        message="Изменения не сохранены. Продолжить без сохранения?"
        actionTitle="Продолжить"
        isOpen={saveModalOpen}
        onAction={() => {
          close();
          setSaveModalOpen(false);
        }}
        onClose={closeSaveModal}
      />

      <ModalCloseOnly
        isOpen={clipboardModalOpen}
        onCloseAction={closeClipboardModal}
        title="Вставить настройки"
        message="Скопированные настройки не подходят для ЕСИА-провайдера."
      />
    </ProviderSettingsSidePanel>
  );
};
