import React, { FC } from 'react';
import styles from './EditEmailTemplates.module.css';
import { EmailTemplate, IEmailTemplate } from './EmailTemplate';
import { EditEmailTemplate } from './EditEmailTemplate';
import { BaseSidePanel } from './sidePanel/BaseSidePanel';

type TEditEmailTemplates = {
  isOpen: boolean;
  close: () => void;
  templates: IEmailTemplate[];
};

export const EditEmailTemplates: FC<TEditEmailTemplates> = ({ isOpen, close, templates }) => {
  const [selectedTemplate, setSelectedTemplate] = React.useState<IEmailTemplate | undefined>(
    undefined,
  );
  const [isEditTemplateOpen, setIsEditTemplateOpen] = React.useState(false);

  return (
    <>
      <BaseSidePanel
        handleClosePanel={close}
        isOpenPanel={isOpen}
        title="Настроить шаблоны писем"
        description="Настройте шаблоны писем для регистрации, восстановления пароля и других событий"
        isNoBackdrop={false}
      >
        <div className={styles.wrapper}>
          {templates.map((template) => {
            return (
              <EmailTemplate
                key={template.action}
                template={template}
                onClick={() => {
                  setSelectedTemplate(template);
                  setIsEditTemplateOpen(true);
                }}
              />
            );
          })}
        </div>
      </BaseSidePanel>
      <EditEmailTemplate
        isOpen={isEditTemplateOpen}
        close={() => {
          setIsEditTemplateOpen(false);
          setSelectedTemplate(undefined);
        }}
        template={selectedTemplate}
      />
    </>
  );
};
