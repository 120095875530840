import React from 'react';
import styles from './ActionButtons.module.css';
import Button from '@mui/material/Button';

interface IActionButtons {
  handleClose: () => void;
  acceptTitle: string;
  acceptAction: () => void;
  disabled?: boolean;
}
export const ActionButtons = ({
  handleClose,
  acceptTitle,
  acceptAction,
  disabled,
}: IActionButtons) => {
  return (
    <div>
      <div className={styles.divider} style={{ marginBottom: 0, width: '100%', marginLeft: 0 }} />
      <div className={styles['bottom-buttons']}>
        <Button onClick={handleClose} variant="custom" color="secondary">
          Отмена
        </Button>
        <Button
          style={{ marginLeft: 24 }}
          disabled={disabled}
          variant="custom"
          type="submit"
          onClick={acceptAction}
        >
          {acceptTitle}
        </Button>
      </div>
    </div>
  );
};
