import React, { FC } from 'react';
import styles from './ProfileField.module.css';
import ListItem from '@mui/material/ListItem';
import { ReactComponent as Lock } from '../../icons/Lock.svg';
import { ReactComponent as World } from '../../icons/World.svg';
import { ReactComponent as Group } from '../../icons/Group.svg';
import { TProfileField, useDeleteProfileFieldMutation } from '../../redux/services/settings';
import { EClaimPrivacy } from '../profile/PublicStatusPopover';
import { IconWithTooltip } from '../IconWithTooltip';
import { CustomTypography } from '../custom/CustomTypography';

type ProfileFieldProps = {
  profile: TProfileField;
  onClick: () => void;
  deleted?: boolean;
};

export const ProfileField: FC<ProfileFieldProps> = ({ profile, onClick, deleted }) => {
  const [deleteProfileField] = useDeleteProfileFieldMutation();

  const required = profile.required;
  const editable = profile.editable;
  const unique = profile.unique;
  const active = profile.active;
  const allowed_as_login = profile.allowed_as_login;
  const profileIconSx = {
    marginRight: '8px',
  };

  let claimIcon = <IconWithTooltip customIcon={<Lock />} customTitle="Доступно только вам" />;
  if (profile.claim === EClaimPrivacy.publicGravatar) {
    claimIcon = <IconWithTooltip customIcon={<World />} customTitle="Доступно всем" />;
  }
  if (profile.claim === EClaimPrivacy.publicOauth) {
    claimIcon = <IconWithTooltip customIcon={<Group />} customTitle="Доступно по запросу" />;
  }

  const handleDelete = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    if (profile.type === 'custom') {
      await deleteProfileField(profile.field);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (profile.field === 'sub') {
      event.preventDefault();
      return;
    }
    onClick();
  };

  return (
    <ListItem
      className={`${styles['profile-item']} ${!active ? styles['inactive'] : ''}`}
      onClick={handleClick}
    >
      <div className={styles['profile-content']}>
        <div className={styles['profile-head']}>
          <CustomTypography className={styles['profile-title']}>{profile.title}</CustomTypography>
          <CustomTypography color="grey" className={styles['profile-field']}>
            {profile.field}
          </CustomTypography>
        </div>
        <div className={styles['profile-icons']}>
          {allowed_as_login && <IconWithTooltip iconType="id" buttonSx={profileIconSx} />}
          {unique ? (
            <IconWithTooltip iconType="oneFilled" buttonSx={profileIconSx} />
          ) : (
            <IconWithTooltip iconType="one" buttonSx={profileIconSx} />
          )}
          {editable ? (
            <IconWithTooltip iconType="penFilled" buttonSx={profileIconSx} />
          ) : (
            <IconWithTooltip iconType="pen" buttonSx={profileIconSx} />
          )}
          {required ? (
            <IconWithTooltip
              iconType="starFilled"
              customTitle="Обязательное"
              buttonSx={profileIconSx}
            />
          ) : (
            <IconWithTooltip iconType="star" customTitle="Небязательное" buttonSx={profileIconSx} />
          )}
          {claimIcon}
          {deleted && (
            <IconWithTooltip
              iconType="delete"
              action={(e) =>
                e && handleDelete(e as React.MouseEvent<HTMLButtonElement, MouseEvent>)
              }
              buttonSx={{
                marginLeft: '8px',
              }}
            />
          )}
        </div>
      </div>
    </ListItem>
  );
};
