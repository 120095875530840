import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../constants';
import { getAccessToken } from '../../service/auth';

export enum TargetType {
  user = 'USER',
  client = 'CLIENT',
  provider = 'PROVIDER',
}

export enum ProviderScope {
  login = 'login',
  trusted = 'trusted',
  otp = 'otp',
  internal = 'internal',
}

export interface TLicenseInfo {
  id: string;
  value: string;
  expiration_time: number;
  providers: string[];
  flags: string[];
}

export interface QueryParams {
  flag?: string;
}

export const licensesApi = createApi({
  reducerPath: 'licensesApi',
  tagTypes: ['Licenses'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/v1/licenses`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getLicenses: builder.query<TLicenseInfo[], QueryParams | void>({
      query: (params) => {
        const queryParams: Record<string, string> = {};

        if (params && typeof params === 'object') {
          Object.keys(params).forEach((key) => {
            const value = params[key as keyof QueryParams];
            if (value !== undefined) {
              queryParams[key] = String(value);
            }
          });
        }

        return {
          url: '',
          params: queryParams,
        };
      },
      providesTags: [{type: 'Licenses', id: 'LIST' }],
    }),

    activateLicense: builder.mutation<void, { value: string; description: string }>({
      query: (body) => ({
        method: 'POST',
        url: ``,
        body,
      }),
      invalidatesTags: [{ type: 'Licenses', id: 'LIST' }],
    }),

    deleteLicense: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        method: 'DELETE',
        url: `/${id}`,
      }),
      invalidatesTags: ['Licenses'],
    }),
  }),
});

export const { useGetLicensesQuery, useActivateLicenseMutation, useDeleteLicenseMutation } =
  licensesApi;
